<template>
  <NavbarCombination :activePage="'ServeCenter'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img v-if="isPhone" src="@/assets/home_images/banner背景-移动端.jpg" alt="">
    <img v-else src="@/assets/home_images/banner背景-pc端.jpg" alt="">
    <div>
      <span>
        助力企业，共创未来
      </span>
      <span>
        落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来
      </span>
    </div>
  </div>

  <div class="have-background-color">
    <div class="container module describe">
      <span style="margin-top: 20px;">
        服务体系
      </span>
      <span class="middle-text">
        易捷软件秉承&nbsp;“用户至上，服务用心”&nbsp;的服务理念，竭诚为您提供全面和完善的服务
      </span>
    </div>
  </div>

  <div class="have-background-img module-top-bottom">
    <div class="container describe">
      <span>
        选择适合您的产品
      </span>
      <span class="middle-text">
        以客户为中心、以客户成功为目标，通过提供标准服务、高级服务以及项目式服务，帮助企业确保业务愿景与技术战略保持一致，持续助力企业实现重要的业务成果和价值。
      </span>
    </div>
    <div class="container module-top-bottom">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="text-box-3-1 text-3box-all">
            <span class="large-text">标准服务</span>
            <span>
              防范风险，提供线上服务，保障产品正常使用，面向所有客户的必备服务
            </span>
            <span>
              需求反馈与实现|故障处理|产品使用答疑......
            </span>
          </div>

        </div>
        <div class="col-md-6 col-12">
          <div class="text-box-3-2 text-3box-all">
            <span class="large-text">高级服务</span>
            <span>
              获得专人线下一对一的指导落地，保障产品高效运行，让产品更好用，面向各类企业及有特地需求客户的VIP服务
            </span>
            <span>
              1V1服务|故障处理|业务响应快速......
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <div class="text-box-3-3 text-3box-all">
            <span class="large-text">项目式服务</span>
            <span>
              全流程落地实施，更多个性化定制服务，推动您业务发展，实现业务价值最大化，面向有个性化需求客户的服务产品集合
            </span>
              <span>
              升级服务|性能优化服务|数据库迁移服务|云备份|个性化定制......
            </span>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div style="padding-bottom: 100px;">
    <div class="title-text"
        style="position: absolute;z-index: 999;text-align: center;width: 100%;color: white;text-shadow:1px 1px 10px rgba(0, 0, 0, 0.8);padding-top: 100px">
      服务流程<br><span class="large-text">用志不分，乃凝于神</span>
    </div>
    <div class="content-banner">
    </div>
    <div class="container service-step-box">
      <div class="service-step-item-box">
        <div class="our-good-job-box">
          <img  src="@/assets/home_images/服务中心图标/产品沟通.png" style="height: 60px;width: 60px">
          <div class="service-step-content">
            <div style="display: flex;align-items: center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="dodgerblue"
                   class="bi bi-1-circle-fill" viewBox="0 0 16 16">
                <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z"/>
              </svg>
              <span style="font-weight: bold" class="large-text">
                &nbsp;产品沟通
              </span>
            </div>
            <div class="small-text">
              <ul>
                <li>告知服务体系流程</li>
                <li>沟通客户需求</li>
                <li>发送产品设计方案</li>
                <li>确认业务需求</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="service-step-item-box">
        <div class="our-good-job-box">
          <img  src="@/assets/home_images/服务中心图标/软件安装.png" style="height: 60px;width: 60px">
          <div class="service-step-content">
            <div style="display: flex;align-items: center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="dodgerblue" class="bi bi-2-circle-fill" viewBox="0 0 16 16">-->
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z"/>
              </svg>
              <span style="font-weight: bold" class="large-text">
                &nbsp;软件安装
              </span>
            </div>
            <div class="small-text">
              <ul>
                <li>沟通确认安装时间</li>
                <li>技术人员全程指导</li>
                <li>不耗时长轻松安装</li>
                <li>服务请求实时响应</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="service-step-item-box">
        <div class="our-good-job-box">
          <img  src="@/assets/home_images/服务中心图标/软件实施.png" style="height: 60px;width: 60px">
          <div class="service-step-content">
            <div style="display: flex;align-items: center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="dodgerblue" class="bi bi-3-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z"/>
              </svg>
              <span style="font-weight: bold" class="large-text">
                &nbsp;软件实施
              </span>
            </div>
            <div class="small-text">
              <ul>
                <li>专门实施顾问</li>
                <li>客户需求分析</li>
                <li>软件设置全程指导</li>
                <li>软件启用培训</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div class="service-step-item-box">
        <div class="our-good-job-box">
          <img  src="@/assets/home_images/服务中心图标/软件培训.png" style="height: 60px;width: 60px">
          <div class="service-step-content">
            <div style="display: flex;align-items: center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="dodgerblue" class="bi bi-4-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z"/>
              </svg>
              <span style="font-weight: bold" class="large-text">
                &nbsp;软件培训
              </span>
            </div>
            <div class="small-text">
              <ul>
                <li>按需拟定培训内容</li>
                <li>定期进行培训</li>
                <li>售后工程师上门培训</li>
                <li>电话视频远程培训</li>
                <li>视频教程或文档资料</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="service-step-item-box">
        <div class="our-good-job-box">
          <img  src="@/assets/home_images/服务中心图标/软件维护.png" style="height: 60px;width: 60px">
          <div class="service-step-content">
            <div style="display: flex;align-items: center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="dodgerblue" class="bi bi-5-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z"/>
              </svg>
              <span style="font-weight: bold" class="large-text">
                &nbsp;软件维护
              </span>
            </div>
            <div class="small-text">
              <ul>
                <li>售后人员远程指导</li>
                <li>技术人员提供支持</li>
                <li>快速解决技术问题</li>
                <li>软件个性化定制开发</li>
                <li>软件版本升级</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="have-background-color2">
    <div class="container hotline">
      <span>
        服务热线：0575-81383566
      </span>
    </div>
  </div>

  <div class="have-background-color2" id="fileDownload">
    <div class="container describe">
      <span>
        资源下载
      </span>
    </div>
    <div v-if="isPhone" class="container">
      <div class="phone-download-box" v-for="(file,index) in files" :key="index">
        <img class="change-this-size" src="@/assets/home_images/服务中心图标/下载图标.png">
        <a class="phone-download-file-box"  :href="file.downloadUrl">
          <span>&nbsp;{{ file.fileName }}</span>
          <span>{{ file.updateTime }}&nbsp;&nbsp;&nbsp;{{ file.fileSize }}</span>
        </a>
      </div>
    </div>
    <table v-else class="container">
      <tr class="first-line">
        <td class="col-1"></td>
        <td class="col-6">文件名</td>
        <td class="col-2">文件大小</td>
        <td class="col-2">更新时间</td>
        <td class="col-1"></td>
      </tr>
      <tr class="" v-for="(file,index) in files" :key="index">
        <td class="col-1"></td>
        <td class="col-6 download-file-box underlined" >
          <a :href="file.downloadUrl">
            <img class="change-this-size" src="@/assets/home_images/服务中心图标/下载图标.png">
            {{ file.fileName }}
          </a>
        </td>
        <td class="col-2 underlined">{{ file.fileSize }}</td>
        <td class="col-2 underlined">{{ file.updateTime }}</td>
        <td class="col-1"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AboutUs",
  data(){
    return {
      screenWidthSize: window.innerWidth,
      isPhone: false,
      files: [],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
    axios.get('/fileDownload/fileList.json?t='+new Date().getTime(), {}).then(res=>{
      //这种写法方便服务更新文件列表, 只需要修改fileList.json
      this.files = res.data;
    });
  },
  methods:{
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      this.isPhone = this.screenWidthSize <= 768;
    },

  }
}
</script>

<style scoped>
a{
  text-decoration:none;
}
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.hotline{
  padding-top: 30px;
  text-align: center;
  font-size: calc(16px + 1vw);
  color: dimgrey;
  padding-bottom: 30px;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
}
.describe span:nth-child(2){
  text-align: center;
}
.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 30px;
}
.have-background-color2{
  background-color: #f9f9f9;
  padding-bottom: 20px;
}
.black-line{
  align-content: center;
}
hr {
  border: none; /* 去除默认边框 */
  height: 2px; /* 设置高度 */
  background-color: #000; /* 线条颜色 */
  margin: 20px 0; /* 上下外边距 */
}
.serve-process-box img{
  height: calc(80px + 5vw);
}
.serve-process-title{
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
}
.serve-process-title svg {
  vertical-align: middle;
  width: 30px;
}
.serve-process-title span{
  vertical-align: middle;
}
.change-this-left-right{
  padding-left: 5%;
  padding-right: 5%;
}
.download-file-box{
  color: dodgerblue;
  cursor: pointer;
}
.first-line{
  color: dimgrey;
}
.change-this-size{
  width: 10px;
  height: 16px;
}

tr:not(:last-child) .underlined {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000; /* 黑色下划线，你可以根据需要调整颜色和粗细 */
}
tr .underlined{
  padding-bottom: 10px;
  padding-top: 10px;
}
table{
  margin-bottom: 30px;
}
.phone-download-box{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.phone-download-box img{
  margin-top: 5px;
}
.phone-download-file-box{
  display: flex;
  flex-direction: column;
}
.phone-download-file-box span:nth-child(1){
  color: dodgerblue;
}
.phone-download-file-box span:nth-child(2){
  color: dimgrey;
  font-size: 12px;
}
.have-background-img{
  background-image: url("@/assets/home_images/流程图背景.jpg");
}
.text-3box-all{
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 200px;
  box-shadow: rgba(153,204,255,0.5) 0 5px 10px;
  justify-content: center;
  padding-left: 20px;
}
.text-3box-all *{
  max-width: 90%;
}
.text-box-3-1{
  margin-right: 10px;
  margin-bottom: 40px;
}
.text-box-3-2{
  margin-left: 10px;
  margin-bottom: 40px;
}
.text-3box-all span:nth-child(1){
  font-weight: bold;
  margin-bottom: 10px;
}
@media (max-width: 1100px) {
  .have-background-color{
    background-color: #f9f9f9;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .serve-process-box img{
    height: 80px;
    width: 80px;
  }
  .every-vertical{
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .vertical-icon-box{
    justify-content: center;
    text-align: right;
    position: relative;
  }
  .vertical-line {
    position: absolute;
    left: 65%;
    bottom: 0;
    width: 2px; /* 线条的粗细 */
    height: 35%; /* 线条的高度，这里设置为视窗的100% */
    background-color: #000; /* 线条的颜色 */
  }
  .show-img div{
    bottom: 30%;
  }
  .text-box-3-1{
    margin-right: 0;
  }
  .text-box-3-2{
    margin-left: 0;
  }
  .describe span:nth-child(2){
    text-align: initial;
  }

  .our-good-job-box{
    display: flex;
    align-items: center;
    height: auto!important;
  }
  .service-step-box {
    margin-top: -150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-step-item-box{
    padding: 0 20px!important;
    width: 100%!important;
  }
  .service-step-item-box ul{
    display: inline-block;
  }

  .service-step-content{
    display: inline-block;
    margin-left:13%;
  }
}

.content-banner {
  background-image: url("@/assets/home_images/优势和底部的背景图.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 400px;
  background-attachment: fixed;
}

.our-good-job-box {
  background: white;
  padding: 30px;
  height: 300px;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
  margin: 2px 2px 20px;
}

.service-step-item-box{
  width: 400px;
  padding: 10px;
}

.service-step-item-box ul{
  margin-top: 10px;
  padding-left: 20px;
}

.service-step-box {
  margin-top: -150px;display: flex;align-items: center
}
</style>
