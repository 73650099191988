<template>
  <div>
    <div class="sidebar">
      <div @click="onSliderClick" :class="sliderIconClass">
        <svg xmlns="http://www.w3.org/2000/svg"  fill="white" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
          <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
      <div :class="sliderBarContentClass">
        <!-- 右侧导航栏内容，不随滚动而滚动 -->
        <div class="box" @mouseenter="visible1=true" @mouseleave="visible1=false" @click="callPhone('0575-81383566')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telephone" viewBox="0 0 16 16">
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          <div class="middle-text">电话</div>
        </div>
        <div class="box" @mouseenter="visible2=true" @mouseleave="visible2=false">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-qr-code" viewBox="0 0 16 16">
            <path d="M2 2h2v2H2V2Z"/>
            <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
            <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
            <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
            <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
          </svg>
          <div class="middle-text">关注</div>
        </div>
        <div class="box" @click="visible3=true" style="cursor: pointer;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
          </svg>
          <div class="middle-text">试用</div>
        </div>
      </div>
    </div>
    <!-- 弹窗1，使用v-if控制显示隐藏 -->
    <div v-if="visible1" class="showInfo1">
      <div class="middle-text">咨询热线：0575-81383566</div>
      <div class="triangle1">
      </div>
    </div>
    <!-- 弹窗2，使用v-if控制显示隐藏 -->
    <div v-if="visible2" class="showInfo2">
      <img src="@/assets/home_images/二维码.jpg">
      <div class="triangle2">
      </div>
    </div>

    <!-- 弹窗3，使用v-if控制显示隐藏 -->
    <div v-if="visible3" class="modal-overlay" >
      <div class="modal-show" @click.stop="">
        <div @click="visible3=false" style="position:absolute;top: 0;right: 15px;font-size: 22px;cursor: pointer">x</div>
        <h6>申请免费体验</h6>
        <input type="text"  v-model="companyName" placeholder="公司名称">
        <span style="display: flex;position: absolute;left: 6%;top: 31%;color: red;">*</span>
        <input type="text" v-model="name" placeholder="姓名" required>
        <span style="display: flex;position: absolute;left: 6%;top: 43%;color: red;">*</span>
        <input type="text" @blur="mouseleave_error" v-model="phoneNumber" placeholder="手机号" required>
        <p v-if="errorMessage" class="errorMsg">{{ errorMessage }}</p>
        <input type="text" v-model="email" placeholder="邮箱">
        <div class="identify-box">
          <input type="text" v-model="inputCode" placeholder="验证码" style="margin-right: 10px;">
          <div @click="changeCode()" style="margin-top: 10px;">
            <identify :identifyCode="identifyCode"></identify>
          </div>
        </div>
        <button @click="submitForm">立即申请</button>
        <span>咨询热线：0575-81383566</span>
      </div>
  </div>

  </div>
</template>

<script>
import axios from 'axios';
import {ref} from 'vue';
import identify from "@/components/SIdentify.vue";


let visibleAll = true;
import { ElMessageBox  } from 'element-plus';
export default {
  name: "RightNavbar",
  components:{
    identify
  },
  data() {
    return {
      errorMessage: '',
      isPhoneNumber: false,
      visible1: false,
      visible2: false,
      visible3: false,
      // 假设手机屏幕定义为宽度小于等于768px
      isPhone: false,
      screenWidthSize: window.innerWidth,
      sliderIconClass:"click-nav",
      sliderBarContentClass:"sidebar_content",
      name : ref(''),
      phoneNumber : ref(''),
      companyName : ref(''),
      email : ref(''),
      create_by : 'www.estoon.com',
      returnState : ref('0'),
      templateCode : 'SMS_470625021',
      targetPhone : '13735380293',

      // 验证码初始值
      identifyCode: 'm6a8',// 这个就是随机生成的验证码
      // 验证码的随机取值范围
      identifyCodes: '123456789abcdefghjkmnpqrstuvwxyz',
      inputCode: '',
    }
  },
  // 获取屏幕宽度
  mounted() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
    // 刷新页面就生成随机验证码
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)

  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    changeCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },

    onSliderClick(){
      if (visibleAll){
        this.sliderIconClass="click-nav left-click-nav"
        this.sliderBarContentClass="sidebar_content sidebar_content_hide"
      }else {
        this.sliderIconClass="click-nav"
        this.sliderBarContentClass="sidebar_content"
      }
      visibleAll = !visibleAll;
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      // 根据屏幕宽度设置isMobile的值
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    // 调用拨号功能
    callPhone(phoneNumber) {
      if (!this.isPhone){
        return
      }
      // window.location.href = "tel:158xxxx1317";直接跳转到手机拨号
      window.location.href = "tel:"+phoneNumber;
    },
    mouseleave_error(){
      // 合并手机号码和座机号码的正则表达式
      const regex = /^(1(3|4|5|6|7|8|9)\d{9})|(\d{3,4}-\d{7,8}(?:-\d{1,4})?)$/;
      if(this.phoneNumber === ''){
        this.errorMessage = '不能为空';
      }else {
        if (!regex.test(this.phoneNumber)){
          this.errorMessage = '格式不正确';
        }else{
          this.isPhoneNumber = true;
          this.errorMessage = '';
        }

      }
    },
    async submitForm(){
      try {
          if (this.name === ''){
            alert('请输入姓名！')
          } else if (this.phoneNumber === ''){
            alert('请输入手机号！')
          } else if (!this.isPhoneNumber){
            alert('请输入正确的手机号！')
          } else if(this.inputCode != this.identifyCode){
            alert('验证码不正确！')
            this.changeCode(); // 刷新验证码
          }else if (this.inputCode === this.identifyCode && this.isPhoneNumber) {

            const currentTime = this.formatDate(new Date());
            let params = new URLSearchParams();
            params.append('companyName', this.companyName);
            params.append('name', this.name);
            params.append('phoneNumber', this.phoneNumber);
            params.append('email', this.email);
            params.append('create_by', this.create_by);
            params.append('currentTime', currentTime);
            params.append('returnState', this.returnState);
            const response = await axios.post('/mtdcApi/plug/applyExperience/add', params);
            this.changeCode(); // 刷新验证码
            ElMessageBox.alert(response.data.msg, response.data.code===0?"申请成功":"系统错误", {
              confirmButtonText: '确定',
            });
          }
      }catch (error){
        console.error("Error during login:", error.message); // 添加错误处理，打印错误信息
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style scoped>
.showInfo1{
  width: calc(2vw + 100px);
  height: 50px;
  position: fixed; /* 设置为固定定位 */
  right: calc(1.5vw + 30px); /* 导航栏靠右显示 */
  top: 57.5%;
  bottom: 0; /* 导航栏底部对齐（可选，根据需要设置） */
  padding: 0;
  background-color: white; /* 设置背景颜色，提高可读性 */
  z-index: 1400;
  border-radius: 4px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
}
.showInfo1 span{
  font-size: 18px;
}
.triangle1{
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* 上方透明 */
  border-bottom: 10px solid transparent; /* 下方透明 */
  border-right: 10px solid transparent; /* 右侧透明 */
  border-left: 10px solid white; /* 箭头颜色 */
  position: absolute;
  bottom: 100%;
  top: 30%;
  left: 100%;
}

.showInfo2{
  display: grid;
  place-items: center;
  position: fixed; /* 设置为固定定位 */
  right: calc(1.5vw + 38px); /* 导航栏靠右显示 */
  top: 61%;
  padding: 0;
  background-color: white; /* 设置背景颜色，提高可读性 */
  z-index: 1400;
  border-radius: 4px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
}
.showInfo2 img{
  width: calc(2vw + 100px);
  height: calc(2vw + 100px);
}
.triangle2{
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* 上方透明 */
  border-bottom: 10px solid transparent; /* 下方透明 */
  border-right: 10px solid transparent; /* 右侧透明 */
  border-left: 10px solid white; /* 箭头颜色 */
  position: absolute;
  bottom: 100%;
  top: 42%;
  left: 100%;
}
.sidebar{
  text-align: center;
  /*width: calc(2vw + 60px); !* 设置导航栏的宽度 *!*/
  position: fixed; /* 设置为固定定位 */
  right: 0; /* 导航栏靠右显示 */
  top: 50%;
  padding: 10px 5px 0 5px;
  overflow: hidden; /* 如果导航项过多，允许垂直滚动（可选） */
  background-color: dodgerblue; /* 设置背景颜色，提高可读性 */
  z-index: 1400;
}
.box{
  display: flex;
  flex-direction: column;
  margin: 8px;
  align-items: center;
}
.sidebar li{
  list-style-type: none;
}
.sidebar svg{
  color: dodgerblue;
  width: calc(1.5vw + 20px);
  height: calc(1.5vw + 20px);
}

.sidebar_content{
  opacity: 1;
  transition: opacity 0.5s ease-out, max-height 0.5s ease-out, max-width 0.5s ease-out; /* 使用transition代替animation，因为我们可以动态控制高度 */
  max-height: 500px; /* 初始时设置一个足够大的max-height来确保内容可见 */
  max-width: 100px;
}

.sidebar_content_hide{
  max-height: 0; /* 折叠时的高度 */
  max-width: 0;
  opacity: 0; /* 折叠时透明度也变为0（可选） */
}

.click-nav svg{
  width: 20px;
  height: 20px;
  text-align: right;
  cursor: pointer;
}
.click-nav svg:hover{
  transform: scale(1.2);
  transition: all 0.2s ease;
}

.click-nav{
  transition: all 0.7s ease;
}

.left-click-nav{
  transform: rotate(180deg);
}
.sidebar .middle-text{
  color: white;
}
.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1500;
}

.modal-show {
  top: 20%;
  left: calc(45vw - 100px);
  width: calc(10vw + 200px);
  height: 440px;
  background-color: white;
  position: absolute;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-show span{
  text-align: center;
  font-size: 14px;
}
.modal-show h6{
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.modal-show input{
  font-size: 14px;
  height: 40px;
  width: 80%;
  color: grey;
  text-align: left;
  padding-left: 10px;
  border: 1px solid darkgray;
  border-radius: 2px;
}
.modal-show * {
  margin-top: 6px;
  margin-bottom: 6px;
}
.box *{
  margin-top: 2px;
  margin-bottom: 2px;
}
.modal-show button{
  width: 80%;
  background-color: dodgerblue;
  color: white;
  font-size: 14px;
  height: 30px;
  border: none;
}
.modal-show button:hover {
  opacity: 50%;
}

.identify-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 40px;
}

.errorMsg{
  position: absolute;
  right: 11%;
  top: 43%;
  background-color: red;
  color: white;
  border-radius: 4px;
  font-size: 10px;
}
@media (max-width: 768px) {
}
</style>
