<template>
  <div v-if="isMobile" class="phone-nav-box">
    <div class="navbar-all phone-nav">
      <!-- 子导航栏3-手机导航栏1 -->
      <div class="navbar-small3" :style="{ transform: `translateY(${navbarVisible3 ? 0 : -100}%)` ,opacity: navbarVisible3 ? 1 : 0}">
        <div class="container">
          <div class="row navbar-container">
            <ul class="lists">
              <li class=""><a href="/">首页</a></li>
              <li class=""><a href="#" @click="toggleNavbar4" style="cursor: pointer;">产品中心</a></li>
              <li class=""><a href="#" @click="toggleNavbar5">解决方案</a></li>
              <li style="display: block;padding-bottom: 10px">
                <a href="/ServeCenter">服务中心</a>
                <a style="font-size: 16px;margin-left: 20px" href="/ServeCenter/#fileDownload">->资源下载</a>
              </li>
              <li class=""><a href="/CooperateCenter">合作中心</a></li>
              <li class=""><a href="/AboutUs">关于我们</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 子导航栏4-手机导航栏1-2 -->
      <div class="navbar-small3" :style="{ transform: `translateX(${navbarVisible4 ? 0 : -100}%)` }">
        <div class="icon-lefts">
          <img src="@/assets/icons/chevron-left.svg" @click="toggleNavbar4">
        </div>
        <div class="container">
          <div class="row navbar-container">
            <ul class="lists" style="padding-top: 20px;padding-bottom: 0;">
              <li class="grid-item"><span><a href="/products" style="color: black;font-weight: bold;font-size: 25px;">软件产品</a></span></li>
              <li class="grid-item"><a href="/product/ERP">ERP企业资源计划</a></li>
              <li class="grid-item"><a href="/product/ASMS">E-ASMS售后管理</a></li>
              <li class="grid-item"><a href="/product/MES">MES制造执行系统</a></li>
              <li class="grid-item"><a href="/product/ObjectCode">一物一码</a></li>
              <li class="grid-item"><a href="/product/WES">E-WES智能仓库执行系统</a></li>
              <li class="grid-item"><a href="/product/MobileApp">移动端应用</a></li>
              <li class="grid-item"><a href="/product/IoTEdge">IoT-Edge物联网边缘计算</a></li>
              <li class="grid-item"><span><a href="/product/HardwareHome" style="color: black;font-weight: bold;font-size: 25px;">硬件产品</a></span></li>
              <li class="grid-item"><a @click="goToSpecificSection(10)">料箱式机器人</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(0)">托举式机器人</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(1)">潜伏式机器人</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(2)">多功能工作站</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(3)">智能终端PDA</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(5)">物联网边缘网关</a></li>
              <li class="grid-item"><a @click="goToSpecificSection(4)">工业平板电脑</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 子导航栏5-手机导航栏1-3 -->
      <div class="navbar-small3" :style="{ transform: `translateX(${navbarVisible5 ? 0 : -100}%)` }">
        <div class="icon-lefts">
          <img src="@/assets/icons/chevron-left.svg" class="icon-left" @click="toggleNavbar5">
        </div>
        <div class="container">
          <div class="row navbar-container">
            <ul class="lists">
              <li class="grid-item"><a href="/solve/ComponentsParts">零部件溯源方案</a></li>
              <li class="grid-item"><a href="/solve/QualityProgram">质检溯源方案</a></li>
              <li class="grid-item"><a href="/solve/CompleteParts">物料齐套率方案</a></li>
              <li class="grid-item"><a href="/solve/CustomerSuppliers">客户/供应商协同方案</a></li>
              <li class="grid-item"><a href="/solve/BirthSilos">产仓一体化方案</a></li>
              <li class="grid-item"><a href="/solve/OneDistribution">仓配一体化方案</a></li>
              <li class="grid-item"><a href="/solve/CustomerSpecific" style="display: none;">客户定制方案</a></li>
              <li class="grid-item"><a href="/solve/SoftHard" style="display: none;">软硬结合方案</a></li>
              <li class="grid-item"><a></a></li>
              <li class="grid-item"><a></a></li>
              <li class="grid-item"><a></a></li>
              <li class="grid-item"><a></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-nav phone-nav">
       <div class="mobile-nav-logo-box">
         <img src="@/assets/home_images/icon.png" alt="易捷信息"/>
         <span>易捷信息</span>
       </div>
       <div class="list-icon" @click="toggleNavbar3">
         <!--            <el-image :src="startIncrement(number)" class="icons"></el-image>-->
         <div :style="{ backgroundImage: 'url(' + imgUrl + ')' } "  class="icons"> </div>
       </div>
     </div>

  </div>
  <div v-else class="PC-nav">
    <div v-show="navbarVisible2 || navbarVisible1" class="full-box" @click="clearNavbar">
    </div>
    <div class="white-box">
      <div class="container">
        <div class="row navbar-container">
          <div class="col-md-2">
            <div class="firm_name">
              <img src="@/assets/home_images/icon.png" alt="易捷信息"/>
              <span>易捷信息</span>
            </div>
          </div>
          <div class="col-md-10">
            <nav>
              <ul class="navbar">
                <li class="nav-item">
                  <a class="nav-link " :class="{ active: activePage === 'home' }" href="/">首页</a>
                </li>
                <li class="nav-item" @mouseenter="isClickable &&(navbarVisible1=true)">
                  <a class="nav-link" :class="{ active: activePage === 'products' }" @click="shortClose1" @mouseleave="isClickable=true" style="cursor: pointer;">产品中心</a>
                </li>
                <li @mouseenter="isClickable && (navbarVisible2=true)">
                  <a class="nav-link" :class="{ active: activePage === 'solves' }" @click="shortClose2" @mouseleave="isClickable=true" style="cursor: pointer;">解决方案</a>
                </li>
                <li>
                  <a class="nav-link" :class="{ active: activePage === 'ServeCenter' }" aria-current="page" href="/ServeCenter">服务中心</a>
                </li>
                <li>
                  <a class="nav-link" :class="{ active: activePage === 'CooperateCenter' }" aria-current="page" href="/CooperateCenter">合作中心</a>
                </li>
                <li>
                  <a class="nav-link" :class="{ active: activePage === 'aboutUs' }" aria-current="page" href="/AboutUs">关于我们</a>
                </li>
              </ul>
            </nav>
          </div>

        </div>
      </div>
    </div>
    <!-- 子导航栏1 -->
    <div class="navbar-small" :style="{ transform: `translateY(${navbarVisible1 ? 0 : -100}%)` }" @mouseleave="navbarVisible1=false">
      <div class="container" >
        <div class="row navbar-container">
          <div class="col-md-5">
            <ul>
              <div class="grid-container1">
                <li class="grid-item"><a :class="{ active: currentChoose === 'software'}" @mouseenter="changeChoose1()">软件产品</a></li>
                <li class="grid-item"><a :class="{ active: currentChoose === 'hardware'}" @mouseenter="changeChoose2()">硬件产品</a></li>
              </div>
            </ul>
          </div>
          <div class="col-md-7">
            <ul>
              <div v-if="show1" class="grid-container2">
                <li class="grid-item"><a href="/product/ERP">ERP企业资源计划</a></li>
                <li class="grid-item"><a href="/product/ASMS">E-ASMS售后管理</a></li>

                <li class="grid-item"><a href="/product/MES">MES制造执行系统</a></li>
                <li class="grid-item"><a href="/product/ObjectCode">一物一码</a></li>

                <li class="grid-item"><a href="/product/WES">E-WES智能仓库执行系统</a></li>
                <li class="grid-item"><a href="/product/MobileApp">移动端应用</a></li>

                <li class="grid-item"><a href="/product/IoTEdge">IoT-Edge物联网边缘计算</a></li>
              </div>
              <div v-else class="grid-container2">
                <li class="grid-item"><a @click="goToSpecificSection(10)">料箱式机器人</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(3)">智能终端PDA</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(0)">托举式机器人</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(4)">工业平板电脑</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(1)">潜伏式机器人</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(5)">物联网边缘网关</a></li>
                <li class="grid-item"><a @click="goToSpecificSection(2)">多功能工作站</a></li>

              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 子导航栏2 -->
    <div class="navbar-small" :style="{ transform: `translateY(${navbarVisible2 ? 0 : -100}%)` }" @mouseleave="navbarVisible2=false">
      <div class="container">
        <div class="row navbar-container">
          <div class="col-md-6">
            <ul>
              <div class="grid-container3 grid-container3-left">
                <li class="grid-item"><a href="/solve/ComponentsParts">零部件溯源方案</a></li>
                <li class="grid-item"><a href="/solve/QualityProgram">质检溯源方案</a></li>
                <li class="grid-item"><a href="/solve/CompleteParts">物料齐套率方案</a></li>
                <li class="grid-item"><a href="/solve/CustomerSuppliers">客户/供应商协同方案</a></li>
              </div>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <div class="grid-container3  grid-container3-right">

                <li class="grid-item"><a href="/solve/BirthSilos">产仓一体化方案</a></li>

                <li class="grid-item"><a href="/solve/OneDistribution">仓配一体化方案</a></li>

                <li class="grid-item"><a href="/solve/CustomerSpecific" style="display: none;">客户定制方案</a></li>

                <li class="grid-item"><a href="/solve/SoftHard" style="display: none;">软硬结合方案</a></li>
              </div>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NavbarCombination",
  data() {
    return {
      number: 0,
      incrementing: true, // 是否正在增加数字
      decrementing: false, // 是否正在减少数字
      incrementInterval: null, // 用于存储增加数字的定时器ID
      decrementInterval: null, // 用于存储减少数字的定时器ID

      navbarVisible1: false, // 控制导航栏是否可见
      navbarVisible2: false, // 控制导航栏是否可见
      navbarVisible3: false, // 控制导航栏是否可见
      navbarVisible4: false, // 控制导航栏是否可见
      navbarVisible5: false, // 控制导航栏是否可见
      // 假设手机屏幕定义为宽度小于等于768px
      isMobile: true,
      screenWidthSize: window.innerWidth,
      imgUrl:require('@/assets/icons/menu-ico00.png'),
      currentChoose: 'software',
      show1: true,
      isClickable: true,
      // 定时器ID变量
      toggleNavbarTimer:null,
    };
  },
  props: {
    // 声明一个名为 activePage 的 prop
    activePage: {
      type: String, // 指定 prop 的类型，这里是字符串
      required: true, // 指定这个 prop 是否是必需的
      default: 'home' // 提供一个默认值，以防没有传递
    },
  },
  // 获取屏幕宽度
  mounted() {
    this.isClickable = !this.$route.query.clickRouter;
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    goToSpecificSection(index) {
      if ('/product/HardwareHome' === this.$route.path) {
        //如果当前已经在这个页面不跳转 直接去
        this.toggleNavbar3();
        let toElement = document.getElementById(index);
        toElement.scrollIntoView(true);
      } else {
        //否则跳转路由
        // localStorage.setItem("toId", index);
        this.$router.push({ path: '/product/HardwareHome' , query: { 'toId': index } });
      }

    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      // 根据屏幕宽度设置isMobile的值
      this.isMobile = this.screenWidthSize <= 1000;
    },
    startIncrement(number) {
      return require('@/assets/icons/menu-ico' + number.toString().padStart(2, '0') + '.png');
    },
// 开始定时增加数字
    toggleNavbar3() {
      // 检查定时器是否已设置，如果是，则直接返回，避免重复调用
      if (this.toggleNavbarTimer) {
        return;
      }
      // 设置定时器，在35*10毫秒后清除toggleNavbarTimer
      this.toggleNavbarTimer = setTimeout(() => {
        // 清除定时器ID，允许再次调用toggleNavbar3
        this.toggleNavbarTimer = null;
      }, 400);


      this.navbarVisible3 = !this.navbarVisible3;
      if (this.navbarVisible4 != false) {
        this.navbarVisible4 = false;
      }
      if (this.navbarVisible5 != false) {
        this.navbarVisible5 = false;
      }
      if (this.incrementing) {
        // 清除之前的增加定时器（如果存在）
        if (this.incrementInterval) {
          clearInterval(this.incrementInterval);
        }
        this.incrementInterval = setInterval(() => {
          if (this.number < 10) {
            this.number++;
            this.imgUrl = this.startIncrement(this.number);
          } else {
            // 如果数字达到10，停止增加
            this.number = 10;
            this.incrementing = false;
            this.decrementing = true;
            this.imgUrl = this.startIncrement(this.number);
            clearInterval(this.incrementInterval);
          }
        }, 35); // 每秒增加一次
      } else if (this.decrementing) {
        // 清除之前的减少定时器（如果存在）
        if (this.decrementInterval) {
          clearInterval(this.decrementInterval);
        }
        this.decrementInterval = setInterval(() => {
          if (this.number > 0) {
            this.number--;
            this.imgUrl = this.startIncrement(this.number);
          } else {
            // 如果数字达到0，停止减少
            this.number = 0;
            this.decrementing = false;
            this.incrementing = true;
            this.imgUrl = this.startIncrement(this.number);
            clearInterval(this.decrementInterval)
          }
        }, 35); // 每秒增加一次
      }
    },
    toggleNavbar4() {
      this.navbarVisible4 = !this.navbarVisible4; // 切换导航栏的显示状态
    },
    toggleNavbar5() {
      this.navbarVisible5 = !this.navbarVisible5; // 切换导航栏的显示状态
    },
    clearNavbar() {
      if (!this.navbarVisible1) {
        this.navbarVisible1 = false;
      }
      if (!this.navbarVisible2) {
        this.navbarVisible2 = false;
      }
    },
    shortClose1(){
      this.isClickable = false;
      window.location.href = '/products?clickRouter=true';
    },
    shortClose2(){
      this.isClickable = false;
      window.location.href = '/solves?clickRouter=true';
    },
    changeChoose1(){
      this.currentChoose = 'software';
      if (this.show1 === false){
        this.show1 = true;
      }
    },
    changeChoose2(){
      this.currentChoose = 'hardware';
      if (this.show1 === true){
        this.show1 = false;
      }
    },
  }
}
</script>

<style scoped>
.phone-nav-box{
  position: relative;
}
.PC-nav{
  position: fixed;
  z-index: 1500;
  width: 100%;
}
.phone-nav{
  position: fixed;
  top: 0;
  z-index: 2000;
}
.full-box{
  width: 100vw;
  height: 100vh;
  z-index: 900;
  position: absolute;
}

.list-icon{
  z-index: 2000;
}
.icons{
  width: 30px;
  height: 30px;
  background-size: 100%;
}
.icon-lefts img{
  width: 25px;
  height: 25px;
}
.lists li{
  text-align: left;
  display: grid;
  /* 定义一列，每列占据相等的空间 */
  grid-template-columns: repeat(1, 1fr);
  /* 定义10行，每行高度为100px */
  grid-template-rows: repeat(6, 2px);
  /* 设置网格项之间的间隙 */
  gap: 5px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 50px;
}
.lists a {
  color: black;
  font-weight: initial;
}
.container{
  position: relative;
}
.white-box{
  background-color: rgba(255,255,255,0.8);
  z-index: 1600;
}
.navbar-container{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.firm_name img,.firm_name span,.navbar li{
  z-index: 1500;
}
.firm_name img{
  width: 3.5vh;
  height: 3.5vh;
}
.firm_name{
  display: flex;
  width: 15vh;
  float: left;
  align-content: center;
  align-items: center;
}
.firm_name span{
  font-size: 2.5vh;
  font-weight: bold;
}
.navbar,.navbar-small,.navbar-small3 {
  display: flex;
  float: right;
  justify-content: space-between; /* 或者使用 flex-start, center, space-around 等 */
  list-style-type: none;
  margin-bottom: 0;
  position: relative;
}
.navbar li,.navbar-small li{
  margin-right: 6vh;
  font-size: 2vh;
}
.navbar a,.navbar-small a,.navbar-small3 a{
  text-decoration: none; /* 去除默认下划线 */
  color: black; /* 颜色 */
  position: relative; /* 为了让伪元素定位到链接上 */
  display: inline-block; /* 让伪元素可以正确地定位 */
}
a::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -0.5vh; /* 下划线与文字之间的间隔 */
  height: 2px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 0; /* 默认隐藏 */
}
a:hover::after {
  /*opacity: 1; !* 悬停时显示下划线 *!*/
  animation: slideAndFadeIn 0.5s forwards; /* 过渡效果 */
}
a:hover{
  color: cornflowerblue;
}

.active{
  color: cornflowerblue!important;
}

.active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -0.5vh; /* 下划线与文字之间的间隔 */
  height: 2px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: opacity 0.3s ease; /* 过渡效果 */
}
@keyframes slideAndFadeIn {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    opacity: 0.5; /* 可选：在动画一半时增加透明度，使效果更明显 */
  }
  100% {
    width: 100%; /* 宽度增加到100% */
    opacity: 1; /* 透明度增加到1，即完全不透明 */
  }
}
.navbar-small3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f8f9fa;
  transition: all 0.3s ease-in-out;
  transform-origin: top;
  z-index: 1600; /* 确保它在其他内容之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-small3 a::after{
  opacity: 0;
}
.navbar-small {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 340px;
  padding-top: 70px;
  background-color: #f8f9fa;
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  z-index: 1000; /* 确保它在其他内容之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-small ul,.navbar-small3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.navbar-small ul li a,.navbar-small3 ul li a {
  text-decoration: none;
  color: dimgrey;
}
.grid-container1 {
  display: grid;
  /* 定义一列，每列占据相等的空间 */
  grid-template-columns: repeat(1, 1fr);
  /* 定义两行，每行高度为100px */
  grid-template-rows: repeat(2, 5vh);
  /* 设置网格项之间的间隙 */
  gap: 10px;
}
.grid-container1 li a{
  float: right;
  font-size: 2vh;
  font-weight: bold;
}
.grid-container2 {
  display: grid;
  /* 定义两列，每列占据相等的空间 */
  grid-template-columns: repeat(2, 1fr);
  /* 定义四行，每行高度为100px */
  grid-template-rows: repeat(4, 5vh);
  /* 设置网格项之间的间隙 */
  gap: 10px;
}


.grid-container2  .grid-item{
  font-size: 16px;
}

.grid-container3 .grid-item{
  font-size: 16px;
}
.grid-item{
  cursor: pointer;
}
.grid-container3 {
  display: grid;
  /* 定义一列，每列占据相等的空间 */
  grid-template-columns: repeat(1, 2fr);
  /* 定义四行，每行高度为100px */
  grid-template-rows: repeat(4, 5vh);
  /* 设置网格项之间的间隙 */
  gap: 10px;
}
.grid-container3-left li a{
  float: right;
}
.vertical-line{
  height: 25vh;
  width: 1px; /* 竖线的宽度 */
  background-color: #000; /* 竖线的颜色 */
  margin-left: auto;
  margin-right: auto; /* 在某些情况下，可能需要这个来确保居中 */
}

.mobile-nav{
  width: 100%;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding: 10px;
  background-color: rgba(255,255,255,0.8);
  backdrop-filter: blur(10px);
}
.mobile-nav-logo-box{
  display: flex;
  align-items: center;
}
.mobile-nav-logo-box img{
  height: 35px;
  width: 35px;
}

.mobile-nav-logo-box span{
  font-weight: bold;
  font-size: 22px;
}
/*.initial-exist{*/
/*  opacity: 0;*/
/*}*/
.hide-navbar{
  opacity: 1;
  animation: rollUp 1s both;
}
@keyframes rollUp {
  0% {
    opacity: 1;
    transform: translateY(0); /* 初始位置 */
  }
  100% {
    opacity: 0;
    transform: translateY(-100%); /* 向上移动至完全不可见，假设门帘条高度是容器高度的整数倍 */
  }
}
.show-navbar{
  opacity: 1;
  animation: showUp 1s both;
}
@keyframes showUp {
  0% {
    opacity: 0;
    transform: translateY(-100%); /* 初始位置 */
  }
  100% {
    transform: translateY(0); /* 向上移动至完全不可见，假设门帘条高度是容器高度的整数倍 */
  }
}


@media (max-width:768px) {
  a:hover::after {
    /*opacity: 1; !* 悬停时显示下划线 *!*/
    opacity: 0;
    display: none;
    animation: slideAndFadeIn 0.5s forwards; /* 过渡效果 */
  }
  .active::after {
    display: none;
  }
}

</style>
